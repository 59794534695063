import { MetaSwiper, SafariSwiper } from "./meta-settings.js";
import Swiper from "https://cdnjs.cloudflare.com/ajax/libs/Swiper/9.0.0/swiper-bundle.esm.browser.js";
import typedJs from "https://cdn.jsdelivr.net/npm/typed.js@2.0.16/+esm";

AOS.init({
  startEvent: "load",
  disableMutationObserver: false,
  duration: 600,
  once: true,
});
AOS.refresh(true);

const header = document.getElementsByClassName(`header`)[0];

// Header scroll
const scrollContainer = () => {
  return document.documentElement || document.body;
};

document.addEventListener("scroll", () => {
  if (scrollContainer().scrollTop > header.clientHeight) {
    header.classList.add("scrolled");
  } else if (scrollContainer().scrollTop === 0) {
    header.classList.remove("scrolled");
  }
});

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

// toggle menu handler
function menuToggle() {
  $(".menu-toggle").toggleClass("active");
  $(".mobile-header").toggleClass("active");
  $(".header-close-wrapper").toggleClass("active");
  $(".header").toggleClass("active");
  // LockScroll when burger open and enable when closed and enable scroll on menu
  scrollLock.getScrollState()
    ? scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"))
    : scrollLock.enablePageScroll();
}
//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;
  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");
    $(".header-close-wrapper").removeClass("active");
    $(".menu-item.active").removeClass("active");
    $(".navbar-nav").removeClass("active");
    $(".menu-toggle").removeClass("active");
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

/* Advantages dropdowns */
const advantagesSection = document.querySelector(".advantages-section");
if (advantagesSection) {
  const advantages = advantagesSection.querySelectorAll(
    ".advantages-list__item"
  );

  let height = 0;

  advantages.forEach((item) => {
    if (item.scrollHeight > 0) {
      height = item.scrollHeight;
    }

    item.style.maxHeight = "140px";

    if (window.innerWidth < 1025) {
      item.addEventListener("click", () => {
        item.classList.toggle("opened");

        if (item.classList.contains("opened")) {
          item.style.maxHeight = item.scrollHeight + "px";
        } else {
          item.style.maxHeight = "140px";
        }
      });
    } else {
      item.addEventListener("mouseenter", () => {
        item.style.maxHeight = item.scrollHeight + "px";
      });
      item.addEventListener("mouseleave", () => {
        item.style.maxHeight = "140px";
      });
    }
  });

  function setHeight() {
    if (window.innerWidth > 1024) {
      advantagesSection.style.height = height + "px";
    } else {
      advantagesSection.style.height = "";
    }
  }

  setHeight();

  window.onresize = () => setHeight();
}

/* Typed in Contact form*/
const contactForm = document.querySelector(".contact-form");
if (contactForm) {
  const typed = new typedJs(".typed-name", {
    strings: ["Imogen", "Jason", "Anna"],
    typeSpeed: 100,
    backSpeed: 100,

    attr: "placeholder",
    bindInputFocusEvents: true,
    loop: true,
  });
}

const typeElements = document.querySelectorAll(".type-target");
if (typeElements.length) {
  typeElements.forEach((type) => {
    new typedJs(type, {
      stringsElement: type.previousElementSibling,
      typeSpeed: 100,
      startDelay: 0,
      backSpeed: 50,
      loop: false,
    });
  });
}

/* Vertical Accordion */
const verticalAccordion =
  document.getElementsByClassName("vertical-accordion")[0];
if (verticalAccordion) {
  const verticalAccordionItemsArr = verticalAccordion.querySelectorAll(
    ".vertical-accordion__item"
  );

  verticalAccordionItemsArr.forEach((item, i) => {
    item.addEventListener("click", () => {
      document
        .querySelector(".vertical-accordion__item.active")
        .classList.remove("active");

      item.classList.add("active");
    });
  });
}

/* ---- Sliders ---- */
const statsSliders = document.querySelectorAll(".stats-swiper");
if (statsSliders.length) {
  statsSliders.forEach((slider) => {
    new Swiper(slider, {
      slidesPerView: 1,
      speed: 500,
      spaceBetween: 20,

      autoplay: {
        delay: 3000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      },

      pagination: {
        el: slider.querySelector(".swiper-pagination"),
        clickable: true,
      },
    });
  });
}
